import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthState } from '../../../store/auth/auth.reducer';
import * as AuthSelectors from '../../../store/auth/auth.selectors';
@Injectable()
export class AuthGuard  {
  private isAuth: boolean;

  constructor(private store: Store<AuthState>, public router: Router) {
    this.store.select(AuthSelectors.isAuth).subscribe(isAuth => this.isAuth = isAuth)
  }

  public canActivate(): boolean {
    if (this.isAuth) return true;
    else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
