import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { AppEffects } from './store/app/app.effects';
import { MainModule } from './routes/main/main.module';
import { AuthModule } from './routes/auth/auth.module';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderService } from './shared/services/loader/loader.service';
import { HttpConfigInterceptor } from '../interceptor/interceptor';
import * as AppReducer from './store/app/app.reducer';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AuthGuard } from './shared/services/guards/auth.guard';
import { CustomRouterStateSerializer } from './custom-router-state-serializer';
import { InvisibleComponent } from './pages/invisible/invisible.component';
import { SplashScreenComponent } from './pages/splash-screen/splash-screen.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/en-150';
import { AppSupportComponent } from './pages/app-support/app-support.component';
import { AppPrivacyComponent } from './pages/app-privacy/app-privacy.component';
import {WINDOW_PROVIDERS} from "./window.provider";
import { RouterModule } from '@angular/router';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

registerLocaleData(locale);

/* import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/it'; */

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `uv.languages.phrases.api`,
    ''
  );
}

export function getCulture() {
  return navigator.language;
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['app', 'auth', 'main'],
    rehydrate: true
  })(reducer);

}
const myMetaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];


@NgModule({ declarations: [
        AppComponent,
        InvisibleComponent,
        SplashScreenComponent,
        AppSupportComponent,
        AppPrivacyComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        RouterModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        // commenting AuthModule and MainModule activates lazy loading for them. At the moment we can't
        // exclude the MainModule because we need his effects at the beginning of app, even before moving to main component
        AuthModule,
        MainModule,
        SharedModule,
        StoreModule.forRoot({
            router: routerReducer,
            app: AppReducer.appReducer
        }, {
            metaReducers: myMetaReducers
        }),
        !environment.production ? StoreDevtoolsModule.instrument({
            name: 'Urban Vision App DevTools',
            maxAge: 50,
            logOnly: false,
            connectInZone: true
        }) : [],
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router',
            serializer: CustomRouterStateSerializer
        }),
        EffectsModule.forRoot([AppEffects])], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        AuthGuard,
        LoaderService,
        DatePipe,
        { provide: LOCALE_ID, useClass: DatePipe, useValue: 'en-150' },
        WINDOW_PROVIDERS,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
