import {AfterContentChecked, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {LoaderService} from './shared/services/loader/loader.service';
import * as AppActions from './store/app/app.actions';
import {AppState} from './store/app/app.state';
import {WINDOW} from "./window.provider";
import {ApiService} from "./shared/services/api.service";

export enum Languages {
  EN = '?language=en',
  IT = '?language=it'
}

@Component({
  selector: 'urban-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentChecked {
  public isLoading: Subject<boolean>;
  public RCisLoading: Subject<boolean>;

  constructor(
    public translate: TranslateService,
    private store: Store<AppState>,
    private loaderService: LoaderService,
    private ref: ChangeDetectorRef,
    @Inject(WINDOW) private window: Window
  ) {
    this.setThemeOnStartup();
    translate.setDefaultLang(Languages.EN);
    translate.addLangs([Languages.EN, Languages.IT]);
    this.useLanguage(Languages.EN);
    this.store.dispatch(AppActions.getConfiguration());
    this.isLoading = this.loaderService.isLoading;
    this.RCisLoading = this.loaderService.RCisLoading;
  }

  // this is placed to check the isLoading observable. This fixes the error in DevMode ExpressionChangedAfterItHasBeenCheckedError
  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  private setThemeOnStartup(): void {
    this.store.dispatch(AppActions.updateHostname({hostname : this.window.location.hostname}))
  }
}
