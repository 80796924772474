import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api.service';
import * as AppActions from './app.actions';
import { WINDOW } from "../../window.provider";
import { Theme } from "../../shared/models/theme";
import { of } from "rxjs";
import * as MainActions from "../main/main.actions";
import { StyleHelper } from "../../helpers/style-helper";

@Injectable()
export class AppEffects {

  private windowInternal: Window;

  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    @Inject(WINDOW) private window: Window
  ) {
    this.windowInternal = window;
  }

  readonly getConfiguration$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AppActions.getConfiguration),
        exhaustMap(() =>
          this.apiService.getConfiguration().pipe(
            map(config => AppActions.setConfiguration(config))
          )
        )
      ),
    {dispatch: true}
  );

  readonly updateHostname$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AppActions.updateHostname),
        exhaustMap(() =>
          this.apiService.getTheme(this.windowInternal.location.hostname).pipe(
            map((res: Theme) => {
              StyleHelper.applyCustomStyle(res);
              return AppActions.setTheme({theme: res});
            }),
            catchError(err => {
              return of(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'}))
            })
          )
        )
      ),
    {dispatch: true}
  );
}
